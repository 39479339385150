var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filterStatus,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"send-multiple-sms":false},on:{"reload":function($event){return _vm.$refs['refClientsList'].refresh()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-col',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center justify-content-sm-start\n        "},[_c('div',{staticClass:"bg-info",staticStyle:{"padding":"5px","border-radius":"30px","padding-left":"15px","padding-right":"15px"}},[_c('span',{staticClass:"text-nowrap",staticStyle:{"color":"#fff"}},[_vm._v(" Total Amount "+_vm._s(_vm.totalAmount == 0 ? "$" + _vm.totalAmount : _vm.totalAmount)+" ")])])])]},proxy:true}])},[_c('b-table',{ref:"refClientsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","api-url":_vm.clientRoute,"items":_vm.myProvider,"fields":_vm.fields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('router-link',{class:_vm.textLink,attrs:{"to":{
              name: 'lead-show',
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))])],1)]}},{key:"cell(amount)",fn:function(data){return [_c('div',{staticClass:"inline",staticStyle:{"position":"relative"}},[(data.item.type_t != 10 && data.item.type_t != 11)?_c('span',{staticClass:"mr-1"},[_vm._v("$ "+_vm._s(data.item.amount))]):_vm._e(),(data.item.type_t == 10 || data.item.type_t == 11)?_c('span',{staticClass:"mr-1"},[_vm._v("$ ("+_vm._s(data.item.amount)+")")]):_vm._e(),(![10,11].includes(data.item.type_t) && parseFloat(data.item.sumref)>0)?_c('span',{staticClass:"text-info"},[_vm._v(" ($"+_vm._s((parseFloat(data.item.amount)-parseFloat(data.item.sumref)).toFixed(2))+") ")]):_vm._e(),(data.item.pending_void_transactions>0 && ![10,11].includes(data.item.type_t))?_c('span',{staticClass:"ml-1"},[_c('b-badge',{staticClass:"badge",staticStyle:{"cursor":"pointer"},attrs:{"variant":"warning","id":("pending-void-transaction-" + (data.item.transaction_id))}},[_c('tabler-icon',{staticClass:"pending-void-transaction",attrs:{"icon":"ClockIcon","size":"18"}})],1),_c('b-tooltip',{attrs:{"target":("pending-void-transaction-" + (data.item.transaction_id)),"triggers":"hover","placement":"top","variant":"warning","title":"Pending Void Transaction"}})],1):_vm._e(),(data.item.type_t == 10 || data.item.type_t == 11 && parseInt(data.item.pending_void_transactions)<1)?_c('feather-icon',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","position":"absolute","left":"70px"},attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.getVoidRefund(data.item.transaction_id)}}}):_vm._e(),(
              data.item.type_t != 10 &&
              data.item.type_t != 11 &&
              data.item.void == 1 &&
              data.item.w_card == 1 &&
              (_vm.currentUser.role_id == 1 ||
                _vm.currentUser.role_id == 2 ||
                _vm.isChief) &&
              data.item.result == 'Approved' && parseInt(data.item.pending_void_transactions)<1
            )?_c('img',{staticStyle:{"cursor":"pointer","color":"red","position":"absolute","left":"70px"},attrs:{"src":_vm.assetsImg + '/images/icons/void.ico',"title":"Void"},on:{"click":function($event){return _vm.voidAuthorize(
                data.item.transaction_id,
                data.item.merchant,
                data.item.amount,
                data.item.lead_name,
                data.item.settlement_date,
                1,
                data.item.sumref

              )}}}):_vm._e(),(
              data.item.type_t != 10 &&
              data.item.type_t != 11 &&
              data.item.void == 0 &&
              data.item.refund == 1 &&
              data.item.w_card == 1 &&
              (_vm.currentUser.role_id == 1 ||
                _vm.currentUser.role_id == 2 ||
                _vm.isChief) &&
              data.item.result == 'Approved'
              && parseInt(data.item.pending_void_transactions)<1
            )?_c('img',{staticStyle:{"cursor":"pointer","color":"green","position":"absolute","left":"70px"},attrs:{"src":_vm.assetsImg + '/images/icons/refund.ico',"title":"Refund"},on:{"click":function($event){return _vm.voidAuthorize(
                data.item.transaction_id,
                data.item.merchant,
                data.item.amount,
                data.item.lead_name,
                data.item.settlement_date,
                2,
                data.item.sumref
              )}}}):_vm._e()],1)]}},{key:"cell(charge)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[(data.item.charge == 0)?_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"success"}}):_vm._e(),(data.item.charge == 1 || data.item.charge == null)?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon"}}):_vm._e()],1)]}},{key:"cell(result)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[(data.item.result == 'Approved')?_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"success"}}):_vm._e(),(data.item.result == 'Unverified')?_c('feather-icon',{staticClass:"text-warning",attrs:{"icon":"ClockIcon"}}):_vm._e(),(
              data.item.result != 'Approved' &&
              data.item.result != 'Unverified'
            )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon"}}):_vm._e()],1)]}},{key:"cell(user_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.user_name)+" - "+_vm._s(_vm._f("myGlobalDay")(data.item.created_at))+" ")])])]}}])})],1),(_vm.modalRefund)?_c('modal-refund',{attrs:{"modalRefund":_vm.modalRefund,"dataVoid":this.dataVoid,"global":this.currentUser},on:{"close":_vm.closeModalRefund,"updateGrid":_vm.updateGrid}}):_vm._e(),(_vm.modalVoidRefund)?_c('modal-void-refund-info',{attrs:{"modalVoidRefund":_vm.modalVoidRefund,"idtransaction":_vm.idtransaction},on:{"closeInfo":_vm.closeModalVoidRefundInfo}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }